<template>
  <main-layout class="padded">
    <div v-if="termStore.currentTerm" class="pvp-container">
      <h1 class="title">{{ termStore.currentTerm.title }}</h1>
      <!-- eslint-disable vue/no-v-html -->
      <wysiwyg-viewer v-html="termStore.currentTerm.text" />
    </div>
  </main-layout>
</template>

<script>
import WysiwygViewer from '@components/Wysiwyg/WysiwygViewer.vue';
import { useTermStore } from '@src/shared/store/useTermStore';

export default {
  name: 'Term',
  components: { WysiwygViewer },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    version: {
      type: [Number, String],
      default: null,
    },
  },
  setup(props) {
    const termStore = useTermStore();

    termStore.fetchTerms({
      code: props.id,
      version: props.version,
    });

    return { termStore };
  },
};
</script>

<style lang="scss" scoped>
.details {
  margin-top: 1em;
  overflow: auto;
}
</style>
