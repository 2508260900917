<template>
  <main-layout class="padded">
    <div class="pvp-container">
      <h1 class="title">{{ $t('global.documents') }}</h1>
      <p class="subtitle">{{ $t('terms.platformTerms') }}</p>

      <div class="pvp-table">
        <div class="table-body">
          <div
            v-for="(term, key) in terms"
            :key="key"
            class="table-row"
          >
            <div class="table-column">{{ term.title }}</div>
            <div class="table-column more">
              <pvp-btn
                :to="{ name: 'term', params: { id: term.id } }"
                variant="link"
                icon-right="angle-right"
                >{{ $t('global.more') }}
              </pvp-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
export default {
  name: 'Terms',
  data() {
    return {
      terms: [
        {
          title: this.$t('terms.privacyPolicy'),
          id: 'privacypolicy',
        },
        {
          title: this.$t('terms.userAgreements'),
          id: 'termsofservice',
        },
        {
          title: this.$t('rule.cookiepolicy'),
          id: 'cookiepolicy',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  margin-top: 1em;
  color: rgba(white, 0.5);
  font-size: 14px;
}

.table {
  &-row {
    @include min-laptop() {
      display: grid;
      grid-template-columns: 1fr 150px;
      .more {
        justify-content: flex-end;
      }
    }
  }

  &-body {
    @include max-laptop() {
      padding: 0 1em;
      .table-column {
        padding: 0.5em 0;
      }
    }
  }
}
</style>
